
import { defineComponent, onMounted, ref, reactive, computed } from "vue";
import { getOrganization } from "@/core/services/JwtService";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";
import {
  checkClaimTransactions,
  getClaim,
  postAction,
  updatePaymentInfo,
  reviewDate,
} from "@/api/claim.api";
import Multiselect from "@vueform/multiselect";
import { useStore } from "vuex";
import DateFloatComponent from "@/components/ABilling/DateFloatComponent.vue";

export interface Data {
  claim: any;
  claimActions: [];
  componentKey: any;
  modal: boolean;
  nextResponsibleParty?: any;
  claimPayers?: any[];
  effectiveDate: any;
}

export default defineComponent({
  name: "Claim Page",
  components: { Multiselect, DateFloatComponent },
  props: ["claimId"],
  setup(props) {
    let organizationId = ref<string | null>("");
    const store = useStore();
    let data = reactive<Data>({
      claim: undefined,
      claimActions: [],
      componentKey: 0,
      modal: false,
      effectiveDate: new Date(),
    });

    onMounted(async () => {
      organizationId.value = getOrganization();
      setCurrentPageTitle("Claim Page");

      data.claimActions = store.getters.allClaimActions;
      const res = await getClaim(props.claimId);
      data.claimPayers = [{ name: "Self", payerId: "Self", id: "null" }];
      if (res.secondaryPayer) data.claimPayers.push(res.secondaryPayer);
      if (res.tertiaryPayer) data.claimPayers.push(res.tertiaryPayer);
      data.nextResponsibleParty = res.nextResponsiblePartyId;
      if (res.nextResponsibleParty == null) data.nextResponsibleParty = "null";
      data.claim = res;
    });

    async function claimUpdated(claim) {
      if (claim) {
        data.claim = claim;
      } else {
        data.claim = await getClaim(data.claim?.id);
      }
    }

    async function sendTransfer() {
      data.modal = false;
      data.claim.nextResponsiblePartyId = data.nextResponsibleParty;
      if (data.claim.nextResponsiblePartyId == "null")
        data.claim.nextResponsiblePartyId = null;
      await updatePaymentInfo(data.claim);
      const res = await postAction({
        claimsId: [data.claim?.id],
        claimAction: 2,
      });
      if (!res) return;
      let text = "Claim was successfully Transfered";

      Swal.fire({
        title: text,
        confirmButtonText: "Ok!",
        icon: "success",
        customClass: {
          confirmButton: "ab-button",
        },
      });
      data.claim = await getClaim(data.claim?.id);
      data.componentKey += 1;
    }

    async function sendAction(action) {
      if (action == 2) {
        data.modal = true;
        return;
      }
      let actionsInfoText = {
        3: "Are you sure you want to Void this Claim?",
      };

      if (action === 3 && data.claim.status == 4) {
        const check = await checkClaimTransactions({
          id: data.claim.id,
        });
        if (check) {
          await Swal.fire({
            title:
              "This Claim cannot be Voided. Please reverse any Payments/Adjustments made for the Claim.",
            confirmButtonText: "Ok",
            customClass: {
              cancelButton: "ab-button-secondary",
              confirmButton: "ab-button",
            },
          }).then(async () => {
            return;
          });
          return;
        }
      }
      const title = actionsInfoText[action];
      if (title) {
        const actionRes = await Swal.fire({
          title: title,
          showDenyButton: true,
          showCancelButton: true,
          confirmButtonText: "Yes",
          denyButtonText: "No",
          allowOutsideClick: false,
          customClass: {
            cancelButton: "ab-button-secondary",
            confirmButton: "ab-button",
          },
        });
        if (!actionRes.isConfirmed) {
          return;
        }
      }

      const res = await postAction({
        claimsId: [data.claim?.id],
        claimAction: action,
      });
      if (!res) return;
      let actionsText = {
        0: "submitted",
        2: "transfer",
        3: "voided",
      };
      let actionText = actionsText[action];
      let text = "";
      if (res.errors && res.errors.length > 0) {
        res.errors.forEach((error) => {
          text = text + "\n" + error;
        });
        Swal.fire({
          title: "Info!",
          text,
          icon: "info",
          customClass: {
            confirmButton: "ab-button",
          },
        });
      } else {
        text = `Claims was successfully ${actionText}.`;
        Swal.fire({
          title: text,
          confirmButtonText: "Ok!",
          icon: "success",
          customClass: {
            confirmButton: "ab-button",
          },
        });
      }

      data.claim = await getClaim(data.claim?.id);
      data.componentKey += 1;
    }

    function checkActionsCount() {
      let res = false;
      data.claimActions.forEach((c) => {
        const r = checkDisplay(data.claim, c);
        if (r) res = true;
      });
      return res;
    }

    const getActiveActions = computed(() => {
      return data.claimActions.filter((action) => {
        return checkDisplay(data.claim, action);
      });
    });

    function checkDisplay(item, action) {
      if (
        action.code === 0 &&
        (item.status === 1 || item.status === 2 || item.status === 8)
      )
        return true;
      if (action.code === 2 && item.status === 4) return true;
      if (
        action.code === 3 &&
        item.status !== 1 &&
        item.status !== 5 &&
        item.status !== 6 &&
        item.status !== 8 &&
        item.status !== 9
      )
        return true;
      return false;
    }

    async function reviewDateChanged(date) {
      await reviewDate(props.claimId, date);
    }

    return {
      organizationId,
      checkDisplay,
      checkActionsCount,
      sendTransfer,
      sendAction,
      reviewDateChanged,
      getActiveActions,
      claimUpdated,
      data,
    };
  },
});
